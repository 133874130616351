import React from 'react'
import Header from '../Home/Header'
import Banner from '../Blogs/Banner'
import Portfolio from '../Home/Portfolio'
import Footer from '../Home/Footer'

export default function Cover() {
  return (
    <>
     <Header/>
     <Banner name={'Portfolio'}
     title={'Portfolio'}/> 
     <Portfolio/>
     <Footer/>
    </>
  )
}
