import { motion } from "framer-motion";
import React from "react";
import { IoBriefcaseOutline } from "react-icons/io5";
import Srvice from "../UI/Srvice";
import { MdOutlineWbSunny, MdTitle } from "react-icons/md";
import { BsBinoculars, BsCardChecklist, BsFillBarChartFill } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
export default function Services() {
  return (
    <section id="service" className="w-[100vw] flex items-center justify-center bg-white overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
        <motion.div
          initial={{ y: 70, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "easeInOut",
            },
          }}
          className="flex items-center flex-col justify-center w-[100%]"
        >
          <h1 className="text-[#252525] text-4xl font-bold">Services</h1>
          <div className="border-[1px] border-b-gray-600 flex items-center justify-center relative mt-5 w-[15%]">
            <div className="absolute w-[40%] border-b-[3px] border-purple-600" />
          </div>
          <p className="mt-5 text-gray-500 tracking-wider">
            Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
            consectetur velit
          </p>
        </motion.div>
        <div className="w-[100%] flex mt-10 text-gray-600 gap-5">
        <div className="w-[100%] grid grid-cols-2 gap-7">
      <Srvice icon={<IoBriefcaseOutline  className="text-[3.5rem] text-purple-600"/>} title={'Lorem ipsum '} delay={0}/>
      <Srvice icon={<BsCardChecklist   className="text-[3.5rem] text-purple-600"/>} title={'deleniti atque'} delay={0.2}/>
      <Srvice icon={<BsFillBarChartFill   className="text-[3.5rem] text-purple-600"/>} title={'dolores et quas '} delay={0}/>
      <Srvice icon={<BsBinoculars   className="text-[3.5rem] text-purple-600"/>} title={'excepturi sint '} delay={0.2}/>
      <Srvice icon={<MdOutlineWbSunny   className="text-[3.5rem] text-purple-600"/>} title={'cupiditate non '} delay={0}/>
      <Srvice icon={<FaRegCalendarAlt  className="text-[3.5rem] text-purple-600"/>} title={' quos dolores'} delay={0.2}/>
             
        </div>
        </div>
      </div>
    </section>
  );
}
