import { motion } from "framer-motion";
import React from "react";
import { FaStar } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import "swiper/css";
import 'swiper/css/effect-fade';
import "swiper/css/navigation";
import { Autoplay, EffectFade,Navigation } from "swiper/modules";
export default function Testimonials() {
  return (
    <section className="w-[100vw] flex items-center justify-center bg-white overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
      <motion.div
          initial={{ y: 70, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "easeInOut",
            },
          }}
          className="flex items-center flex-col justify-center w-[100%]"
        >
          <h1 className="text-[#252525] text-4xl font-bold">Testimonials</h1>
          <div className="border-[1px] border-b-gray-600 flex items-center justify-center relative mt-5 w-[15%]">
            <div className="absolute w-[40%] border-b-[3px] border-purple-600" />
          </div>
          <p className="mt-5 text-gray-500 tracking-wider">
            Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
            consectetur velit
          </p>
        </motion.div>
        <div className="mt-10 w-[100%] flex overflow-hidden justify-center items-center relative">
        <div
          style={{ zIndex: 9 }}
          className="absolute  w-[100%] px-[2rem]  flex justify-between items-center "
        >
          <motion.div
          initial={{
            opacity:0.8
          }}
            whileHover={{
                opacity:1,
                transition:{
                    duration:0.5
                }
            }}
            transition={{ duration: 0.3 }}
            className="  hover:opacity-100 opacity-80"
          >
            <FaChevronLeft className="text-[2.5rem] custom-prev text-black" />
          </motion.div>
          <motion.div
           
           initial={{
            opacity:0.8
          }}
            whileHover={{
                opacity:1,
                transition:{
                    duration:0.5
                }
            }}
            className=""
          >
            <FaChevronRight className="text-[2.5rem] custom-prev text-black"  />
          </motion.div>
        </div>
        <Swiper
        effect={'fade'}
          navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Navigation,Autoplay]}
          className="w-[100%] flex overflow-hidden"
        >
            <SwiperSlide>

            <div className="w-[100%] items-center justify-center flex flex-col gap-5">
                <div className="w-[70%] flex flex-col gap-5 items-center justify-center">
                    <div className="w-[8rem] rounded-full overflow-hidden h-[8rem]">
                        <img src={require('../Assets/testimonials-1.jpg')} className="w-[1005] object-cover" alt="testimonial" />
                        </div>      

                        <h1 className="text-2xl text-[#252525] font-bold ">Testimonial Name</h1>   
                        <span className="text-md text-gray-500 -mt-5">Position</span>  
                        <div className="flex items-center gap-2 text-xl text-[#d8f537]">
                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                            </div>        
                            <p className="text-center w-[100%] text-gray-600">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates nesciunt aspernatur qui modi, quidem quibusdam sed eligendi nobis quaerat unde ullam voluptate explicabo sit quo, error labore expedita maiores! Id.</p>
                </div>

            </div>
            </SwiperSlide>
            <SwiperSlide>

<div className="w-[100%] items-center justify-center flex flex-col gap-5">
    <div className="w-[70%] flex flex-col gap-5 items-center justify-center">
        <div className="w-[8rem] rounded-full overflow-hidden h-[8rem]">
            <img src={require('../Assets/testimonials-1.jpg')} className="w-[1005] object-cover" alt="testimonial" />
            </div>      

            <h1 className="text-2xl text-[#252525] font-bold ">Testimonial Name</h1>   
            <span className="text-md text-gray-500 -mt-5">Position</span>  
            <div className="flex items-center gap-2 text-xl text-[#d8f537]">
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                </div>        
                <p className="text-center w-[100%] text-gray-600">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates nesciunt aspernatur qui modi, quidem quibusdam sed eligendi nobis quaerat unde ullam voluptate explicabo sit quo, error labore expedita maiores! Id.</p>
    </div>

</div>
</SwiperSlide>
        </Swiper>
        </div>
      </div>
    </section>
  );
}
