import React from "react";
import { FaUser } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";
import BlogCard from "../UI/Blog";

export default function Blogs() {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="w-[100%] px-[7%] py-[5rem] max-w-[1400px] flex items-center justify-center">
        <div className="w-[100%] grid grid-cols-3 gap-5">
         <BlogCard/>
         <BlogCard/>
         <BlogCard/> <BlogCard/>
         <BlogCard/>
         <BlogCard/>
        </div>
      </div>
    </section>
  );
}
