import logo from './logo.svg';
import './App.css';
import HomeContainer from './Components/Home/HomeContainer';
import BlogCover from './Components/Blogs/BlogCover';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BlogDetailsCover from './Components/BlogDetails/BlogDetailsCover';
import ServiceCover from './Components/ServiiceDetail/ServiceCover';
import PortfolioCover from './Components/PortfolioDetails/PortfolioCover';
import AboutCover from './Components/AboutDetils/AboutCover';
import Cover from './Components/ProjectsPage.jsx/Cover';
import Connects from './Components/ContactUs/Connects';

function App() {
  return (
   <BrowserRouter>
   <Routes>
    <Route path="/" element={<HomeContainer />} />
    <Route path="/about" element={<AboutCover />} />
    <Route path="/project" element={<Cover />} />
    <Route path="/contact" element={<Connects />} />

    <Route path="/blog" element={<BlogCover />} />
    <Route path="/blog/:id" element={<BlogDetailsCover />} />
    <Route path="/service/:id" element={<ServiceCover />} />
    <Route path="/portfolio/:id" element={<PortfolioCover />} />

   </Routes>
   {/* <BlogCover/> */}
{/* <HomeContainer/> */}
   </BrowserRouter>
  );
}

export default App;
