import React from "react";
import { FaRegCheckCircle, FaTag, FaUser } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";
import { MdOutlineBusinessCenter } from "react-icons/md";

export default function Details() {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center pb-[2rem] pt-[4rem] ">
        <div className="w-[100%] flex gap-5 ">
          <div className="w-[30%] flex flex-col gap-5">

        <div className="w-[100%] border-[1px] border-gray-300 h-fit flex flex-col gap-3 p-5 ">
        <div className="border-l-[3px] border-purple-500 px-4 py-1">
          <h1 className="text-lg font-semibold">
          <a href="/service/1">
          Web Design
            </a>
          </h1>
        </div>
        <div className="border-l-[3px]  px-4 py-1">
          <h1 className="text-lg text-gray-400 font-semibold">
          <a href="/service/1">
          Software Development
            </a>
          </h1>
        </div>
        <div className="border-l-[3px] px-4 py-1">
          <h1 className="text-lg text-gray-400 font-semibold">
          <a href="/service/1">
          Product Management
            </a>
          </h1>
        </div>
        <div className="border-l-[3px] px-4 py-1">
          <h1 className="text-lg text-gray-400 font-semibold">
            <a href="/service/1">
            Graphic Design
            </a>
          </h1>
        </div>
        <div className="border-l-[3px] px-4 py-1">
          <h1 className="text-lg text-gray-400 font-semibold">
            <a href="/service/1">
            Marketing
            </a>
          </h1>
        </div>
          </div>
          <h1 className="text-xl font-bold">Enim qui eos rerum in delectus</h1>
          <p className="text-md text-gray-600">Nam voluptatem quasi numquam quas fugiat ex temporibus quo est. Quia aut quam quod facere ut non occaecati ut aut. Nesciunt mollitia illum tempore corrupti sed eum reiciendis. Maxime modi rerum.</p>
          </div>
          <div className="w-[65%] shadow-lg flex flex-col gap-5">
            <img
              src={require("../../Components/Assets/blog-1.jpg")}
              className="w-[100%]"
              alt="blog details"
            />
            <div className="p-4 flex flex-col gap-5">
              <h1 className="text-2xl font-bold tracking-wider ">
              Temporibus et in vero dicta aut eius lidero plastis trand lined voluptas dolorem ut voluptas
              </h1>
            
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Suscipit ab consequatur facere rerum quis ex dolore rem quae,
                quam debitis voluptatem eveniet consectetur at commodi
                provident. Voluptatibus ipsum dolor dolorem!
              </p>
              <div className="w-[100%] flex flex-col gap-4">
                <div className="flex gap-3 items-center ">
                <FaRegCheckCircle className="text-purple-600 text-2xl" />
                <h1>
                Aut eum totam accusantium voluptatem.</h1>
                </div>
                <div className="flex gap-3 items-center ">
                <FaRegCheckCircle className="text-purple-600 text-2xl" />
                <h1>
                Aut eum totam accusantium voluptatem.</h1>
                </div>
                <div className="flex gap-3 items-center ">
                <FaRegCheckCircle className="text-purple-600 text-2xl" />
                <h1>
                Aut eum totam accusantium voluptatem.</h1>
                </div>

              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Excepturi quia consequuntur ad iusto nobis consequatur inventore
                odit temporibus voluptates necessitatibus repudiandae eveniet
                itaque libero, expedita totam harum! Facere, eos soluta.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Excepturi quia consequuntur ad iusto nobis consequatur inventore
                odit temporibus voluptates necessitatibus repudiandae eveniet
                itaque libero, expedita totam harum! Facere, eos soluta.
              </p>
           
             
             
           
            </div>
          </div>
        
        </div>
      </div>
    </section>
  );
}
