import React from 'react'

export default function Action() {
  return (
    <section className='w-[100vw] h-[100%] relative flex items-center justify-center overflow-hidden'>
        <img src={require('../Assets/cta-bg.jpg')} alt="action" 
        className='absolute top-0 w-[100%] h-[100%] -z-10 object-cover'
        />
      <div className="max-w-[1400px] bg-[#2525259c] w-[100%] h-[109%] flex flex-col gap-8 items-center justify-center py-[5rem] text-white">
                <h1 className='text-3xl font-bold'>
                Call To Action
                </h1>
                <p className='w-[70%] text-center font-semibold tracking-wider'>
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                </p>
                <div className='border-[2px] text-xl hover:bg-purple-600 transition-all duration-500 hover:border-purple-600 border-white w-fit px-7 rounded-sm py-3 '>
                Call To Action
                </div>
      </div>
    </section>
  )
}
