import { motion } from "framer-motion";
import React from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosArrowRoundForward } from "react-icons/io";
export default function About() {
  return (
    <section id="about" className="w-[100vw] flex items-center justify-center bg-white overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
        <motion.div
          initial={{ y: 70, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "easeInOut",
            },
          }}
          className="flex items-center flex-col justify-center w-[100%]"
        >
          <h1 className="text-[#252525] text-4xl font-bold">About Us</h1>
          <div className="border-[1px] border-b-gray-600 flex items-center justify-center relative mt-5 w-[15%]">
            <div className="absolute w-[40%] border-b-[3px] border-purple-600" />
          </div>
          <p className="mt-5 text-gray-500 tracking-wider">
            Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
            consectetur velit
          </p>
        </motion.div>
        <div className="w-[100%] flex mt-10 text-gray-600 gap-5">
          <motion.div
            initial={{
              y: 100,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                type: "easeInOut",
                delay: 0.3,
              },
            }}
            className="w-[50%] flex flex-col gap-4"
          >
            <h1 className="text-lg uppercase text-gray-400">Who We Are</h1>
            <h1 className="text-4xl font-bold text-[#252525]">
              Unleashing Potential with Creative Strategy
            </h1>
            <p>
              <i>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </i>
            </p>
            <div className="flex items-center gap-3">
              <FaRegCircleCheck className="text-purple-600 text-[1.5rem]" />
              <span>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </span>
            </div>
            <div className="flex items-center gap-3">
              <FaRegCircleCheck className="text-purple-600 text-[1.5rem]" />
              <span>
                Duis aute irure dolor in reprehenderit in voluptate velit
              </span>
            </div>
            <div className="gap-3 inline-flex">
              <span className="">
                <FaRegCircleCheck className="text-purple-600 inline-block mr-2   text-[1.5rem]" />
                Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate trideta
                storacalaperda mastiro dolore eu fugiat nulla pariatur.
              </span>
            </div>
            <div
              className="flex items-center gap-2 text-lg font-semibold text-white px-5 py-3 rounded-lg 
                    bg-purple-500 w-fit tracking-wider hover:bg-purple-400 transition-all duration-500 cursor-pointer
                    "
            >
              Read More
              <IoIosArrowRoundForward />
            </div>
          </motion.div>
          <motion.div
            initial={{
              y: 100,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                type: "easeInOut",
                delay: 0.5,
              },
            }}
            className="w-[50%] flex gap-5"
          >
            <div className="w-[50%] rounded-xl overflow-hidden ">
              <img
                src={require("../Assets/about-company-1.jpg")}
                className="w-[100%] h-[100%] object-cover"
                alt="about"
              />
            </div>
            <div className="w-[50%] h-[100%] flex flex-col gap-5 overflow-hidden ">
              <div className="h-[50%] w-[100%] rounded-xl overflow-hidden">
                <img
                  src={require("../Assets/about-company-2.jpg")}
                  className="w-[100%] h-[100%] object-cover"
                  alt="about"
                />
              </div>
              <div className="h-[50%] w-[100%] rounded-xl overflow-hidden">
                <img
                  src={require("../Assets/about-company-3.jpg")}
                  className="w-[100%] h-[100%] object-cover"
                  alt="about"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
