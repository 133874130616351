import { motion } from 'framer-motion'
import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { MdOutlineMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
export default function Contact() {
  return (
    <section id='contact' className="w-[100vw] flex items-center justify-center bg-white overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
        <motion.div
          initial={{ y: 70, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "easeInOut",
            },
          }}
          className="flex items-center flex-col justify-center w-[100%]"
        >
          <h1 className="text-[#252525] text-4xl font-bold">Contact</h1>
          <div className="border-[1px] border-b-gray-600 flex items-center justify-center relative mt-5 w-[15%]">
            <div className="absolute w-[40%] border-b-[3px] border-purple-600" />
          </div>
          <p className="mt-5 text-gray-500 tracking-wider">
            Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
            consectetur velit
          </p>
        </motion.div>
        <div className='w-[100%] flex-col flex items-center justify-center mt-14'>
            <div className="grid grid-cols-3 items-center ">
                <div className="w-[100%] border-r-[1px] border-gray-300 items-center flex flex-col justify-center gap-2">
                <FaLocationDot className="text-[3rem] text-purple-500" />
                <h1 className='text-[1.7rem] font-bold text-[#252525]'>Address</h1>
                <p className='mt-2 text-sm text-gray-500 w-[80%] text-center'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam cumque</p>
                </div>
                <div className="w-[100%] border-r-[1px] border-gray-300 items-center flex flex-col justify-center gap-2">
                <MdOutlinePhoneInTalk  className="text-[3rem] text-purple-500" />
                <h1 className='text-[1.7rem] font-bold text-[#252525]'>Call Us</h1>
                <p className='mt-2 text-sm text-gray-500 w-[80%] text-center'>+1 5589 55488 55</p>
                </div>
                <div className="w-[100%]  items-center flex flex-col justify-center gap-2">
                <MdOutlineMailOutline  className="text-[3rem] text-purple-500" />
                <h1 className='text-[1.7rem] font-bold text-[#252525]'>Email Us</h1>
                <p className='mt-2 text-sm text-gray-500 w-[80%] text-center'>info@example.com</p>
                </div>
            </div>
            <form className='w-[100%] flex flex-col gap-5 items-center justify-center shadow-lg p-[1.5rem] mt-10'>
                <div className='w-[100%] flex gap-5 items-center'>
                    <input type="text" placeholder='Your name' name='name' className='outline-none border-[1px] border-gray-300  hover:border-purple-600 duration-500 rounded-lg px-4 py-2 w-[50%] ' />
                    <input type="email" placeholder='Your email' name='email' className='outline-none border-[1px] border-gray-300 hover:border-purple-600 duration-500 rounded-lg px-4 py-2 w-[50%] ' />

                </div>
                <input type="text" placeholder='Subject' name='subject' className='outline-none border-[1px] border-gray-300  hover:border-purple-600 duration-500 rounded-lg px-4 py-2 w-[100%] ' />
                <textarea name="message" rows={6} placeholder='Message' id="" className='outline-none border-[1px] border-gray-300  hover:border-purple-600 duration-500 rounded-lg px-4 py-2 w-[100%] '></textarea>
                    <button className='px-5 py-2 bg-purple-600 text-white font-semibold text-lg hover:bg-purple-500 duration-300 rounded-full'>
                        Send Message
                    </button>
            </form>

        </div>
        </div>
    </section>
  )
}
