import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { CiLink, CiZoomIn } from 'react-icons/ci'
export default function Projects({data,time}) {
   const [card,setCard]= useState(false)
  return (
    <motion.div
    initial={{
        scale:0.8,
        opacity:0,
    
    }}
    animate={{
        scale: 1,
        opacity:1,
    }}
    transition={{
        duration: 0.5,
        delay:time
    }}
    onMouseEnter={() => setCard(true)}
    onMouseLeave={() => setCard(false)} className="   overflow-hidden relative flex items-center justify-center w-[100%]">
    <img src={data.image} alt="app" className='w-[100%] h-[100%] object-cover' />
    <motion.div

    animate={{opacity:card?1:0,y:card?0:40}}
    transition={{
        duration: 0.5,
      
        y: card?0:40,
        opacity:card?1:0,
    }}
    className="absolute bottom-0 m-[1rem] w-[92%] bg-white p-3">
        <div className='w-[100%] flex flex-col gap-2'>
                <h1 className='text-gray-800 font-semibold text-xl'>{data?.name}</h1>   
                <div className='w-[100%] flex justify-between gap-4'>
                    <p className='text-gray-500 w-[60%]'>Lorem ipsum, dolor sit amet consectetur</p>
                    <div className='flex items-center gap-2 text-[2rem]'>
                    <CiZoomIn  className='cursor-pointer hover:text-purple-500 duration-500'/>
                    <a href="/portfolio/1">
                    <CiLink  className='cursor-pointer hover:text-purple-500 duration-500'/>
                    </a>
                    </div>
                    </div>     
        </div>
    </motion.div>
    </motion.div> 
  )
}
