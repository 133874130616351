import React from 'react'
import { FaUser } from 'react-icons/fa'
import { IoTimeOutline } from 'react-icons/io5'

export default function BlogCard() {
  return (
        <a href="/blog/1">
    <div className="w-[100%] flex flex-col gap-5 shadow-md">
            <div className="w-[100%] h-[15rem] overflow-hidden ">
              <img
                src={require("../Assets/blog-1.jpg")}
                className="w-[100%]"
                alt=""
              />
            </div>
            <div className="px-5 pb-5 flex flex-col gap-5">
              <h1 className="text-xl font-semibold tracking-wider leading-[1.7rem] hover:text-purple-600 duration-500">
                Dolorum optio tempore voluptas dignissimos cumque fuga qui
                quibusdam quia
              </h1>
              <div className="flex gap-5 items-center text-gray-400">
                <div className="flex items-center  gap-2">
                  <FaUser />
                  <h1>John Doe</h1>
                </div>
                <div className="flex items-center  gap-2">
                  <IoTimeOutline />
                  <h1>Jan 1, 2022</h1>
                </div>
              </div>
              <p className="text-lg tracking-wide font-semibold">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
                repudiandae error atque voluptatibus incidunt quod cumque
                suscipit nam nemo animi ipsa,
              </p>
              <div className="bg-purple-600 text-white font-bold tracking-wider hover:bg-purple-500 duration-200 text-center px-4 py-2 rounded-sm w-fit justify-end">
                Read More
              </div>
            </div>
          </div>
        </a>
  )
}
