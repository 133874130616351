import React from "react";
import { FaTag, FaUser } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";
import { MdOutlineBusinessCenter } from "react-icons/md";

export default function Details() {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center pb-[2rem] pt-[4rem] ">
        <div className="w-[100%] flex gap-5 ">
          <div className="w-[65%] shadow-lg flex flex-col gap-5">
            <img
              src={require("../../Components/Assets/blog-1.jpg")}
              className="w-[100%]"
              alt="blog details"
            />
            <div className="p-4 flex flex-col gap-5">
              <h1 className="text-2xl font-bold tracking-wider ">
                Dolorum optio tempore voluptas dignissimos cumque fuga qui
                quibusdam quia
              </h1>
              <div className="flex gap-5 items-center text-gray-400">
                <div className="flex items-center  gap-2">
                  <FaUser />
                  <h1>John Doe</h1>
                </div>
                <div className="flex items-center  gap-2">
                  <IoTimeOutline />
                  <h1>Jan 1, 2022</h1>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Suscipit ab consequatur facere rerum quis ex dolore rem quae,
                quam debitis voluptatem eveniet consectetur at commodi
                provident. Voluptatibus ipsum dolor dolorem!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Excepturi quia consequuntur ad iusto nobis consequatur inventore
                odit temporibus voluptates necessitatibus repudiandae eveniet
                itaque libero, expedita totam harum! Facere, eos soluta.
              </p>
              <div className="bg-slate-100 py-[1rem]">
                <p className="border-l-[3px] border-purple-600 px-[3rem] py-[2rem] text-2xl text-center">
                  <i>
                    Et vero doloremque tempore voluptatem ratione vel aut.
                    Deleniti sunt animi aut. Aut eos aliquam doloribus minus
                    autem quos.
                  </i>
                </p>
              </div>
              <p>
                Sed quo laboriosam qui architecto. Occaecati repellendus omnis
                dicta inventore tempore provident voluptas mollitia aliquid. Id
                repellendus quia. Asperiores nihil magni dicta est suscipit
                perspiciatis. Voluptate ex rerum assumenda dolores nihil
                quaerat. Dolor porro tempora et quibusdam voluptas. Beatae aut
                at ad qui tempore corrupti velit quisquam rerum. Omnis dolorum
                exercitationem harum qui qui blanditiis neque. Iusto autem
                itaque. Repudiandae hic quae aspernatur ea neque qui. Architecto
                voluptatem magni. Vel magnam quod et tempora deleniti error
                rerum nihil tempora.
              </p>
              <h1 className="text-2xl font-bold tracking-wide">
                Et quae iure vel ut odit alias.
              </h1>
              <p>
                Officiis animi maxime nulla quo et harum eum quis a. Sit hic in
                qui quos fugit ut rerum atque. Optio provident dolores atque
                voluptatem rem excepturi molestiae qui. Voluptatem laborum omnis
                ullam quibusdam perspiciatis nulla nostrum. Voluptatum est
                libero eum nesciunt aliquid qui. Quia et suscipit non sequi.
                Maxime sed odit. Beatae nesciunt nesciunt accusamus quia aut
                ratione aspernatur dolor. Sint harum eveniet dicta
                exercitationem minima. Exercitationem omnis asperiores natus
                aperiam dolor consequatur id ex sed. Quibusdam rerum dolores
                sint consequatur quidem ea. Beatae minima sunt libero soluta
                sapiente in rem assumenda. Et qui odit voluptatem. Cum quibusdam
                voluptatem voluptatem accusamus mollitia aut atque aut.
              </p>
              <img
                src={require("../../Components/Assets/blog-inside-post.jpg")}
                className="w-[100%]"
                alt=""
              />
              <h1 className="text-2xl font-bold tracking-wide">
                Et quae iure vel ut odit alias.
              </h1>
              <p>
                Officiis animi maxime nulla quo et harum eum quis a. Sit hic in
                qui quos fugit ut rerum atque. Optio provident dolores atque
                voluptatem rem excepturi molestiae qui. Voluptatem laborum omnis
                ullam quibusdam perspiciatis nulla nostrum. Voluptatum est
                libero eum nesciunt aliquid qui. Quia et suscipit non sequi.
                Maxime sed odit. Beatae nesciunt nesciunt accusamus quia aut
                ratione aspernatur dolor. Sint harum eveniet dicta
                exercitationem minima. Exercitationem omnis asperiores natus
                aperiam dolor consequatur id ex sed. Quibusdam rerum dolores
                sint consequatur quidem ea. Beatae minima sunt libero soluta
                sapiente in rem assumenda. Et qui odit voluptatem. Cum quibusdam
                voluptatem voluptatem accusamus mollitia aut atque aut.
              </p>
              <div className="w-[100%] border-t-[1px] border-gray-400 py-3 px-2 text-gray-500">
              <div className="flex gap-5 items-center text-gray-400">
                <div className="flex items-center  gap-2">
                <MdOutlineBusinessCenter />
                  <h1>Business</h1>
                </div>
                <div className="flex items-center  gap-2">
                <FaTag />
                  <h1>Creative, Tips, Marketing</h1>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="w-[30%] h-fit flex flex-col gap-5 p-5 shadow-lg">
            <div className="flex w-[100%] flex-col gap-5">
            <h1 className="text-2xl font-bold">Categories</h1>
            <div className="flex flex-col gap-3 text-md text-gray-400">
                <h1 className="hover:text-purple-600 transition-all font-semibold cursor-pointer">General (25)</h1>
                <h1 className="hover:text-purple-600 transition-all font-semibold cursor-pointer">Lifestyle (12)</h1>
                <h1 className="hover:text-purple-600 transition-all font-semibold cursor-pointer">Educaion (14)</h1>
                <h1 className="hover:text-purple-600 transition-all font-semibold cursor-pointer">Creative (8)</h1>
                <h1 className="hover:text-purple-600 transition-all font-semibold cursor-pointer">Design (22)</h1>
            </div>
            </div>
            <h1 className="text-2xl font-bold">Recent Posts</h1>
            <div className="flex flex-col gap-5 w-[100%]">
            <div className="w-[100%] flex gap-2">
                <img src={require('../Assets/blog-1.jpg')} className="w-[30%] h-fit" alt="" />
                <div className="flex flex-col gap-0">
                    <h1 className="font-bold">Nihil blanditiis at in nihil autem</h1>
                    <span className="italic text-gray-500">Jan 1, 2024</span>

                </div>
            </div>
            <div className="w-[100%] flex gap-2">
                <img src={require('../Assets/blog-1.jpg')} className="w-[30%] h-fit" alt="" />
                <div className="flex flex-col gap-0">
                    <h1 className="font-bold">Nihil blanditiis at in nihil autem</h1>
                    <span className="italic text-gray-500">Jan 1, 2024</span>

                </div>
            </div>
            <div className="w-[100%] flex gap-2">
                <img src={require('../Assets/blog-1.jpg')} className="w-[30%] h-fit" alt="" />
                <div className="flex flex-col gap-0">
                    <h1 className="font-bold">Nihil blanditiis at in nihil autem</h1>
                    <span className="italic text-gray-500">Jan 1, 2024</span>

                </div>
            </div>
            <div className="w-[100%] flex gap-2">
                <img src={require('../Assets/blog-1.jpg')} className="w-[30%] h-fit" alt="" />
                <div className="flex flex-col gap-0">
                    <h1 className="font-bold">Nihil blanditiis at in nihil autem</h1>
                    <span className="italic text-gray-500">Jan 1, 2024</span>

                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
