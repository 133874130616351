import { motion } from 'framer-motion'
import React from 'react'

import Teams from '../UI/Teams'
export default function Team() {
  return (
    <section id='team' className="w-[100vw] flex items-center justify-center bg-white overflow-hidden">
    <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
      <motion.div
        initial={{ y: 70, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            type: "easeInOut",
          },
        }}
        className="flex items-center flex-col justify-center w-[100%]"
      >
        <h1 className="text-[#252525] text-4xl font-bold">Team </h1>
        <div className="border-[1px] border-b-gray-600 flex items-center justify-center relative mt-5 w-[15%]">
          <div className="absolute w-[40%] bootom-0 border-b-[3px] border-purple-600" />
        </div>
      
      </motion.div>
      <div className='w-[100%] mt-10 grid grid-cols-4 gap-7'>
        <Teams time={0}/>
        <Teams time={0.3}/>
        <Teams time={0.5}/>
        <Teams time={0.6}/>

      </div>
      </div>
    </section>
  )
}
