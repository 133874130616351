import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
export default function Lear({ icon, title, href,delay }) {
  const [card, setCard] = useState(false);
  return (
    <motion.div
    initial={{
        y:70,
        opacity:0
    }}
    whileInView={{
        y:0,
        opacity:1,
        transition:{
            duration:0.8,
            delay:delay,
            ease:'easeIn'
        }
    }}
      onMouseEnter={() => setCard(true)}
      onMouseLeave={() => setCard(false)}
      className="w-[100%] flex gap-3"
    >
      <div>{icon}</div>
      <div className="flex flex-col gap-2">
        <h1 className={`text-2xl transition-all duration-500 cursor-pointer font-bold tracking-wider ${card ? 'text-purple-700' :'text-gray-500'} `}>
          {title}
        </h1>
        <p className="text-md tracking-wide text-gray-500">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae quis
          consequuntur,{" "}
        </p>
        <div className={`flex gap-2 items-center  cursor-pointer
        ${card ? 'text-purple-700':'text-gray-500'} transition-all duration-500 font-semibold
         `}>
          <h1>Learn More</h1>
          <FaLongArrowAltRight />
        </div>
      </div>
    </motion.div>
  );
}
