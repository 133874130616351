import app1 from '../Assets/app-1.jpg'
import app2 from '../Assets/app-2.jpg'
import p1 from '../Assets/product-1.jpg'
import p2 from '../Assets/product-2.jpg'
import p3 from '../Assets/product-3.jpg'
import b1 from '../Assets/branding-1.jpg'
import b2 from '../Assets/branding-3.jpg'
import bk1 from '../Assets/books-1.jpg'
import bk2 from '../Assets/books-2.jpg'
const data = [
    {
        name:'App 1',
        id:1,
        type:1,
        image:app1,
        description:'Lorem ipsum, dolor sit amet consectetur'

    },
    {
        name:'App 2',
        id:2,
        type:1,
        image:app2,
        description:'Lorem ipsum, dolor sit amet consectetur'

    },
    {
        name:'App 3',
        id:3,
        type:1,
        image:app1,
        description:'Lorem ipsum, dolor sit amet consectetur'

    },
    {
        id:4,
       type:2,
        name:'Product 1',
        image:p1,
        description:'Lorem ipsum, dolor sit amet consectetur'
    },
    {
        id:5,
       type:2,
        name:'Product 2',
        image:p2,
        description:'Lorem ipsum, dolor sit amet consectetur'
    },
    {
        id:6,
       type:2,
        name:'Product 6',
        image:p3,
        description:'Lorem ipsum, dolor sit amet consectetur'
    },
    {
        id:7,
        type:3,
        name:'Branding 1',
        image:b1,
        description:'Lorem ipsum, dolor sit amet consectetur'
    },
    {
        id:8,
        type:3,
        name:'Branding 2',
        image:b2,
        description:'Lorem ipsum, dolor sit amet consectetur'
    },
    {
        id:9,
        type:3,
        name:'Branding 3',
        image:b1,
        description:'Lorem ipsum, dolor sit amet consectetur'
    },
    {
        id:11,
        type:4,
        name:'Book 3',
        image:bk1,
        description:'Lorem ipsum, dolor sit amet consectetur'
    },
    {
        id:12,
        type:4,
        name:'Book 2',
        image:bk2,
        description:'Lorem ipsum, dolor sit amet consectetur'
    }

]
export default data;