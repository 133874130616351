import React from 'react'

export default function Experi() {
  return (
    <section className="w-[100vw ] flex items-center justify-center bg-slate-200 overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
      <div className='grid grid-cols-4 w-[100%] gap-5 items-center'>
            <div className='w-[100%] flex flex-col gap-2 items-center justify-center'>
                <h1 className='text-5xl font-bold text-purple-600 '>232</h1>
                <p className='text-xl font-medium text-gray-500'>Clients</p>

            </div>
            <div className='w-[100%] flex flex-col gap-2 items-center justify-center'>
                <h1 className='text-5xl font-bold text-purple-600 '>521</h1>
                <p className='text-xl font-medium text-gray-500'>Projects</p>

            </div>
            <div className='w-[100%] flex flex-col gap-2 items-center justify-center'>
                <h1 className='text-5xl font-bold text-purple-600 '>1453
                </h1>
                <p className='text-xl font-medium text-gray-500'>Hours Of Support

</p>

            </div>
            <div className='w-[100%] flex flex-col gap-2 items-center justify-center'>
                <h1 className='text-5xl font-bold text-purple-600 '>32</h1>
                <p className='text-xl font-medium text-gray-500'>Workers</p>

            </div>
      </div>
    </div>
    </section>
  )
}
