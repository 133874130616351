import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
export default function Details() {
  return (
    <section className="w-[100vw] flex items-center justify-center bg-white overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
        <div className="w-[100%] flex gap-5 ">
          <div className="w-[60%] ">
            <Swiper
              effect={"fade"}
              navigation={{
                nextEl: ".custom-next",
                prevEl: ".custom-prev",
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Navigation, Autoplay]}
              className="w-[100%] flex overflow-hidden"
            >
              <SwiperSlide>
                <img
                  src={require("../Assets/app-1.jpg")}
                  className="w-[100%] h-[100%] object-cover"
                  alt="details"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../Assets/app-2.jpg")}
                  className="w-[100%] h-[100%] object-cover"
                  alt="details"
                />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="w-[40%] flex flex-col gap-5">
            <div className="w-[100%] h-fit shadow-lg p-5">
              <h1 className="text-2xl font-bold tracking-wide">Project information</h1>
              <div className="border-b-[1px] my-[2rem] w-[100%] border-gray-200"/>
              <div className="flex flex-col gap-2">
                <div className="text-gray-600 flex gap-2"><b>Category :</b>Web design</div>
                <div className="text-gray-600 flex gap-2"><b>Client :</b>Company Name</div>
                <div className="text-gray-600 flex gap-2"><b>Project date :</b>01 March, 2024</div>
                <div className="text-gray-600 flex gap-2"><b>Project URL :</b><a href="#" className="text-purple-600 font-bold">www.example.com</a></div>
                

              </div>
            </div>
            <div className="flex flex-col gap-2">
                <h1 className="text-2xl font-bold">Exercitationem repudiandae officiis neque suscipit</h1>
                <p className="leading-[1.7rem]">Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
