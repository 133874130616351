import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
export default function DisabledAccordion() {
  return (
    <section className="w-[100vw] bg-slate-100 flex items-center justify-center  overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center py-[4rem] ">
        <motion.div
          initial={{ y: 70, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "easeInOut",
            },
          }}
          className="flex items-center flex-col justify-center w-[100%]"
        >
          <h1 className="text-[#252525] text-4xl font-bold">
            Frequently Asked Questions
          </h1>
          <div className="border-[1px] border-b-gray-600 flex items-center justify-center relative mt-5 w-[15%]">
            <div className="absolute w-[40%] border-b-[3px] border-purple-600" />
          </div>
          <p className="mt-5 text-gray-500 tracking-wider">
            Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
            consectetur velit
          </p>
        </motion.div>
        <div className="w-[100%] mt-10 flex gap-5 ">
          <div className="w-[50%] flex flex-col gap-5">
            <Accordion className="border-[1px] border-gray-200 hover:border-purple-600 py-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h1 className="text-lg font-bold hover:text-purple-600 duration-500">
                  Non consectetur a erat nam at lectus urna duis?
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
                  volutpat lacus laoreet non curabitur gravida. Venenatis lectus
                  magna fringilla urna porttitor rhoncus dolor purus non.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="border-[1px] border-gray-200 hover:border-purple-600 py-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <h1 className="text-lg font-bold hover:text-purple-600 duration-500">
                  Feugiat scelerisque varius morbi enim nunc faucibus?
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Dolor sit amet consectetur adipiscing elit pellentesque
                  habitant morbi. Id interdum velit laoreet id donec ultrices.
                  Fringilla phasellus faucibus scelerisque eleifend donec
                  pretium. Est pellentesque elit ullamcorper dignissim. Mauris
                  ultrices eros in cursus turpis massa tincidunt dui
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="border-[1px] border-gray-200 hover:border-purple-600 py-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <h1 className="text-lg font-bold hover:text-purple-600 duration-500">
                  Dolor sit amet consectetur adipiscing elit pellentesque?
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                  sagittis orci. Faucibus pulvinar elementum integer enim. Sem
                  nulla pharetra diam sit amet nisl suscipit. Rutrum tellus
                  pellentesque eu tincidunt. Lectus urna duis convallis
                  convallis tellus. Urna molestie at elementum eu facilisis sed
                  odio morbi quis
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="w-[50%] flex flex-col gap-5">
            <Accordion className="border-[1px] border-gray-200 hover:border-purple-600 py-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h1 className="text-lg font-bold hover:text-purple-600 duration-500">
                  Non consectetur a erat nam at lectus urna duis?
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
                  volutpat lacus laoreet non curabitur gravida. Venenatis lectus
                  magna fringilla urna porttitor rhoncus dolor purus non.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="border-[1px] border-gray-200 hover:border-purple-600 py-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <h1 className="text-lg font-bold hover:text-purple-600 duration-500">
                  Feugiat scelerisque varius morbi enim nunc faucibus?
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Dolor sit amet consectetur adipiscing elit pellentesque
                  habitant morbi. Id interdum velit laoreet id donec ultrices.
                  Fringilla phasellus faucibus scelerisque eleifend donec
                  pretium. Est pellentesque elit ullamcorper dignissim. Mauris
                  ultrices eros in cursus turpis massa tincidunt dui
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="border-[1px] border-gray-200 hover:border-purple-600 py-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <h1 className="text-lg font-bold hover:text-purple-600 duration-500">
                  Dolor sit amet consectetur adipiscing elit pellentesque?
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                  sagittis orci. Faucibus pulvinar elementum integer enim. Sem
                  nulla pharetra diam sit amet nisl suscipit. Rutrum tellus
                  pellentesque eu tincidunt. Lectus urna duis convallis
                  convallis tellus. Urna molestie at elementum eu facilisis sed
                  odio morbi quis
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}
