import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { CiLink, CiZoomIn } from "react-icons/ci";
import Projects from '../UI/Projects';
import data from '../Constant/Projects';
export default function Portfolio() {
   const [project,setProject]= useState(data)
   const handleFilter=(type)=>{
    if(type === 5){
       return setProject(data)
    }
    const filteredProject=data.filter((project)=>project.type==type)
    setProject(filteredProject)
   }
   
  return (
    <section id='project' className='w-[100vw] flex items-center justify-center overflow-hidden'>
      <div className="max-w-[1400px] flex-col w-[100%] px-[7%] py-[4rem] flex items-center justify-center gap-5">
      <motion.div
          initial={{ y: 70, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "easeInOut",
            },
          }}
          className="flex items-center flex-col justify-center w-[100%]"
        >
          <h1 className="text-[#252525] text-4xl font-bold">Portfolio</h1>
          <div className="border-[1px] border-b-gray-600 flex items-center justify-center relative mt-5 w-[15%]">
            <div className="absolute w-[40%] border-b-[3px] border-purple-600" />
          </div>
          <p className="mt-5 text-gray-500 tracking-wider">
            Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
            consectetur velit
          </p>
        </motion.div>
        <div className='w-[100%] mt-10 flex flex-col gap-3 items-center justify-center'>
            <div className='w-[100%] flex items-center justify-center gap-5 text-lg font-bold uppercase'>
                <h1  onClick={()=>handleFilter(5)} className='hover:text-purple-600 duration-500 cursor-pointer'>ALL</h1>
                <h1 onClick={()=>handleFilter(1)}  className='hover:text-purple-600 duration-500 cursor-pointer'>App</h1>
                <h1 onClick={()=>handleFilter(2)}  className='hover:text-purple-600 duration-500 cursor-pointer'>Product</h1>
                <h1  onClick={()=>handleFilter(3)} className='hover:text-purple-600 duration-500 cursor-pointer'>Branding</h1>
                <h1 onClick={()=>handleFilter(4)}  className='hover:text-purple-600 duration-500 cursor-pointer'>Books</h1>

            </div>
            <div className='grid grid-cols-3 gap-5'>
             {project && project.map((data,index)=><Projects key={data.id} data={data} time={index*0.2}/>   )   } 
            
            </div>

        </div>
      </div>
    </section>
  )
}
