import { motion } from 'framer-motion'
import { a } from 'framer-motion/client'
import React, { useState } from 'react'

export default function Srvice({icon,title,delay}) {
   const [card,setCard]= useState(false)
  return (
    <a href='/service/1'>

    <motion.div
    initial={{ y: 100,
         opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              delay:delay,
              ease: 'easeInOut',
              when: "beforeChildren",
              staggerChildren: 0.2,
            },
          }}
    onMouseEnter={() => setCard(true)}
    onMouseLeave={() => setCard(false)} className="w-[100%] border-[1px] border-gray-200 transition-all duration-500 flex gap-5 hover:border-purple-600 rounded-xs p-[2rem]">
        <div className="flex gap-6">

   {icon}
    <div className="flex flex-col gap-2">
        <h1 className={`text-xl ${card && 'text-purple-600 '} duration-500 capitalize font-bold`}>
       {title}</h1>
        <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident
        </p>

    </div>
        </div>
    </motion.div>
    </a>
  )
}
