import React from 'react'
import Header from './Header'
import Banner from './banner'
import Learn from './Learn'
import About from './About'
import Experi from './Experi'
import Services from './Services'
import Action from './Action'
import Portfolio from './Portfolio'
import Testimonials from './Testimonials'
import Team from './Team'
import DisabledAccordion from './Faqs'
import Contact from './Contact'
import Footer from './Footer'

export default function HomeContainer() {
  return (
    <>
     <Header/> 
    <Banner/>
    <Learn/>
    <About/>
    <Experi/>
    <Services/>
    <Action/>
    <Portfolio/>
    <Testimonials/>
    <Team/>
    <DisabledAccordion/>
    <Contact/>
    <Footer/>
    </>
  )
}
