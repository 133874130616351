import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import "swiper/css";
import 'swiper/css/effect-fade';
import "swiper/css/navigation";
import { EffectFade,Navigation } from "swiper/modules";
import { motion } from "framer-motion";
export default function Banner() {
  const [arrow, setArrow] = useState(false);
  console.log(arrow);
  return (
    <section className="w-[100vw] flex h-[100%] justify-center overflow-hidden items-center ">
        <div className="max-w-[1400px] flex w-[100%] items-center justify-center">

      <div
        onMouseEnter={() => setArrow(() => true)}
        onMouseLeave={() => setArrow(() => false)}
        className="w-[100%] relative h-[40rem] flex items-center justify-center "
      >
        <div
          style={{ zIndex: 9 }}
          className="absolute  w-[100%] px-[2rem]  flex justify-between items-center "
        >
          <motion.div
          initial={{
            opacity:0.8
          }}
            whileHover={{
                opacity:1,
                transition:{
                    duration:0.5
                }
            }}
            transition={{ duration: 0.3 }}
            className="  hover:opacity-100 opacity-80"
          >
            <FaChevronLeft className="text-[2.5rem] custom-prev text-white" />
          </motion.div>
          <motion.div
           
           initial={{
            opacity:0.8
          }}
            whileHover={{
                opacity:1,
                transition:{
                    duration:0.5
                }
            }}
            className=""
          >
            <FaChevronRight className="text-[2.5rem] custom-prev text-white"  />
          </motion.div>
        </div>
        <Swiper
        effect={'fade'}
          navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          loop={true}
          modules={[Navigation,EffectFade]}
          className="w-[100%] flex overflow-hidden"
        >
          <SwiperSlide>
            <div div className="w-[100%] h-[100%]  bg-[#ffffff5b]  relative overflow-hidden items-center flex ">
              <div className="absolute top-0 w-[100%] h-[100%]  bg-[#0303038e]" />
              <img
                src={require("../Assets/hero-carousel-5.jpg")}
                alt=""
                className="h-[100%] w-[100%] object-cover r"
              />
              <div className="w-[100%] flex items-center justify-center absolute flex-col gap-10">

              <div style={{zIndex:999}} className="w-[60%] flex flex-col  gap-7 justify-center items-center ">
                <motion.div
                initial={{
                  
                    rotateX:90,
                    y:-100
                }}
                whileInView={{
                
                    rotateX:0,
                    y:0,
                    transition:{
                        duration:0.7
                    }
                }}
                className="flex flex-col gap-7">

                <h1 className="text-[white] text-[48px] uppercase font-[700] ">
                Magnam aliquam quaerat
                </h1>
                <p className="text-lg w-[100%]  text-left font-semibold tracking-wider leading--[1.7rem] text-white">
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut.
                </p>
                </motion.div>
                <motion.div 
                initial={{
                    y:70
                }}
                whileInView={{
                    y:0,
                    transition:{
                        duration:0.7
                    }
                }}
                className="flex justify-center cursor-pointer items-center gap-5 bg-purple-600  text-white capitalize text-lg font-bold px-5 rounded-full py-2">
                       Get Started
                </motion.div>
              </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div div className="w-[100%] h-[100%]  bg-[#ffffff5b]  relative overflow-hidden items-center flex ">
              <div className="absolute top-0 w-[100%] h-[100%]  bg-[#0303038e]" />
              <img
                src={require("../Assets/hero-carousel-2.jpg")}
                alt=""
                className="h-[100%] w-[100%] object-cover r"
              />
              <div className="w-[100%] flex items-center justify-center absolute flex-col gap-10">

              <div style={{zIndex:999}} className="w-[60%] flex flex-col  gap-7 justify-center items-center ">
              <motion.div
                initial={{
                   
                    rotateX:90,
                    y:-100
                }}
                whileInView={{
                    
                    rotateX:0,
                    y:0,
                    transition:{
                        duration:0.7
                    }
                }}
                className="flex flex-col gap-7">

                <h1 className="text-[white] text-[48px] uppercase font-[700] ">
                Magnam aliquam quaerat
                </h1>
                <p className="text-lg w-[100%]  text-left font-semibold tracking-wider leading--[1.7rem] text-white">
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut.
                </p>
                </motion.div>
                <motion.div 
                initial={{
                    y:70
                }}
                animate={{
                    y:0,
                }}
                transition={
                   { duration:0.7}
                }
                className="flex justify-center cursor-pointer items-center gap-5 bg-purple-600  text-white capitalize text-lg font-bold px-5 rounded-full py-2">
                  Get Started
                </motion.div>
              </div>
              </div>
            </div>
          </SwiperSlide>
          
        </Swiper>
      </div>
        </div>
    </section>
  );
}
