import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { CiInstagram } from 'react-icons/ci'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
export default function Teams({time}) {
   const [card,setCard]= useState(false)
  return (
    <motion.div
    initial={{
        opacity:0,
        scale:0.8
    }}
    whileInView={{
        opacity:1,
        scale:1,
        transition:{
          duration:0.3,
            delay:time
        }
    }}
    onMouseEnter={() => setCard(true)}
    onMouseLeave={() => setCard(false)} className="w-[100%]  ">
    <div className='w-[100%] relative flex flex-col items-center justify-center'>
        <img src={require('../Assets/team-1.jpg')} alt="team11" className='w-[100%]'/>
        <motion.div
        animate={{
            height:card?'100%':0,
            
            opacity:card?1:0,
            transition:{
                height:{duration:0.3},
                opacity:{duration:0.3}
            }
        }}
        className='absolute top-0 w-[100%] text-white flex flex-col justify-center items-center  bg-[#000000c5] h-[100%]'>
            <h1 className='text-2xl font-bold text-center'>Team Name</h1>
            <span className='text-sm mt-1'>Position</span>
            <div className='flex text-3xl mt-3 gap-1 items-center '>
            <CiInstagram /><FaFacebookSquare /><FaLinkedin /><FaXTwitter />
            </div>
        </motion.div>

    </div>
</motion.div>
  )
}
