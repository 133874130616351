import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function Header() {
    const [stick, setStick] = useState();
 
  window.onscroll = function () {
    myFunction();
  };
  // var header=document.getElementsByClassName('nav');
  // var sticky=header.offsetTop;
  function myFunction() {
    if (window.scrollY > 40) {
      setStick(true);
      console.log(stick);
    } else {
      setStick(false);
    }

    console.log(window.scrollY);
  }
  return (
    <header style={{zIndex:99999}} className={`w-[100vw] fixed ${stick ? 'bg-[#000000e6] text-white' :'bg-transparent text-white'} h-fit transition-all duration-700 top-0 flex items-center justify-center overflow-hidden`}>
      <div className='max-w-[1400px]  w-[100%] px-[10%] py-4 flex items-center justify-center'>
            <div className='w-[100%] flex items-center justify-between'>
                <h1 className='text-purple-700 text-4xl font-semibold '>PeopleSuccess.<span className='text-red-600'>in</span>
                </h1>
                <div className='flex items-center gap-7 text-sm uppercase font-semibold'>
               <a href="/" className='hover:text-purple-600 transition-all duration-300'> Home</a>
               <a href="/about" className='hover:text-purple-600 transition-all duration-300'> About</a>
               
               <a href="/#service" className='hover:text-purple-600 transition-all duration-300'> Services</a>
               <a href="/project" className='hover:text-purple-600 transition-all duration-300'> Portfolio</a>
               <a href="/#team" className='hover:text-purple-600 transition-all duration-300'> Team</a>
               <a href="/blog" className='hover:text-purple-600 transition-all duration-300'> Blog</a>
               <a href="/contact" className='hover:text-purple-600 transition-all duration-300'> Contact</a>

                </div>
            </div>
      </div>
    </header>
  )
}
