import React from "react";

export default function Banner({name,title}) {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="w-[100%] flex bg3  items-center justify-center">
        <div className="px-[5%] w-[100%] py-[10rem] bg-[#1a19199d] flex flex-col gap-5 items-center justify-center">
          <h1 className="text-5xl font-bold text-white">{name}</h1>
          <p className="text-white">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit
          </p>
          <div className="flex items-center gap-3">
            <div className="flex text-purple-600 font-semibold items-center gap-3 ">
                Home  / <span className="text-white">{title}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
