import React from 'react'
import Header from '../Home/Header'
import Banner from '../Blogs/Banner'
import Footer from '../Home/Footer'
import Details from './Details'

export default function PortfolioCover() {
  return (
    <>
     <Header/>
     <Banner name={'Portfolio Details'} 
     title={'Portfolio Details'}/>
     <Details/>
     <Footer/>
    </>
  )
}
