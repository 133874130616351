import React from 'react'
import Header from '../Home/Header'
import Banner from '../Blogs/Banner'
import Contact from '../Home/Contact'
import Testimonials from '../Home/Testimonials'
import Footer from '../Home/Footer'

export default function Connects() {
  return (
    <>
     <Header/>
     <Banner name={'Contact Us'}
     title={'Contact'}/>
     <Contact/>
     <Testimonials/>
     <Footer/> 
    </>
  )
}
