import React from 'react'
import Header from '../Home/Header'
import Banner from '../Blogs/Banner'
import About from '../Home/About'
import Testimonials from '../Home/Testimonials'
import Faqs from '../Home/Faqs'
import Footer from '../Home/Footer'
export default function AboutCover() {
  return (
    <>
     <Header/>
     <Banner name={'About Us'} 
     title={'About Us'}/>
     <About/>
     <Testimonials/>
     <Faqs/>
     <Footer/>

    </>
  )
}
