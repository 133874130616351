import React from "react";
import { FaFacebookF, FaInstagramSquare, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
  return (
    <section className="w-[100vw] flex items-center justify-center bg-[#060606] text-white overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[8%] flex flex-col items-center justify-center pb-[2rem] pt-[4rem] ">
        <div className="w-[100%] flex justify-between ">
          <div className="w-[30%] flex flex-col gap-6">
            <h1 className="text-purple-700 text-3xl font-semibold ">
              PeopleSuccess.<span className="text-red-600">in</span>
            </h1>
            <div className="flex flex-col text-md gsp-1">
              <span>A108 Adam Street</span>
              <span>New York, NY 535022</span>
            </div>
            <div className="flex flex-col -mt-2 text-md gsp-1">
              <span>Phone: +91 1234 567 890</span>
              <span>Email: info@example.com</span>
            </div>
            <div className="flex gap-3 items-center">
                <div className="p-2 hover:text-purple-600 hover:border-purple-600 duration-300 cursor-pointer  text-[1rem] border-[3px] rounded-full border-white items-center flex justify-center">
                <FaXTwitter />
                </div>
                <div className="p-2 hover:text-purple-600 hover:border-purple-600 duration-300 cursor-pointer  text-[1rem] border-[3px] rounded-full border-white items-center flex justify-center">
                <FaFacebookF  />
                </div>
                <div className="p-2  hover:text-purple-600 hover:border-purple-600 duration-300 cursor-pointer text-[1rem] border-[3px] rounded-full border-white items-center flex justify-center">
                <FaLinkedinIn  />
                </div>
                <div className="p-2 hover:text-purple-600 hover:border-purple-600 duration-300 cursor-pointer  text-[1rem] border-[3px] rounded-full border-white items-center flex justify-center">
                <FaInstagramSquare   />
                </div>
            </div>
          </div>
          <div className="w-[65%] flex gap-10">
            <div className="flex w-[50%] flex-col gap-5">
                <h1 className="text-lg font-bold hover:text-purple-600">Useful Links</h1>

                <div className="flex flex-col gap-2 text-md font-semibold">
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                        Home
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                        About
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Services
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Terms of service
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Privacy policy
                    </span>

                </div>
            </div>
            <div className="flex w-[70%] flex-col gap-5">
                <h1 className="text-lg font-bold hover:text-purple-600">Our Services</h1>

                <div className="flex flex-col gap-2 text-md font-semibold">
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Web Design
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Web Development
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Product Management
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Marketing
                    </span>
                    <span className="hover:text-purple-500 cursor-pointer duration-300">
                    Graphic Design
                    </span>

                </div>
            </div>
            <div className="flex flex-col gap-5">
                <h1 className="text-xl font-semibold hover:text-purple-600 ">
                Our Newsletter
                </h1>
                <p className="w-[]">Subscribe to our newsletter and receive the latest news about our products and services!</p>
                <form action="" className="flex border-[1px] border-gray-300 rounded-md">
                    <input type="email" name="emai" id="" className="h-[100%] px-2 outline-none py-2 bg-transparent" />
                    <button className="h-[100%] text-center bg-purple-600 px-3">Subscribe</button>
                </form>
            </div>
          </div>
        </div>
        <div  className="border-b-[1px] border-gray-700 mt-10  w-[100%]"/>
        <div className="pt-[2rem] w-[100%] flex flex-col gap-1 items-center justify-center">
            <h1 className="text-md font-semibold">© Copyright <span className="text-purple-500"> PeopleSuccess.in</span> All Rights Reserved</h1>
            <span className="text-sm font-semibold tracking-wider">Designed by <span className="text-[#af4add] font-bold">UNITECHNO</span> </span>
        </div>
      </div>
    </section>
  );
}
