import React from 'react'
import Header from '../Home/Header'
import Banner from '../Blogs/Banner'
import Footer from '../Home/Footer'
import Details from './Details'

export default function BlogDetailsCover() {
  return (
    <>
      <Header/>
      <Banner name={'Blog Title'} title={'Blog Name'}/>
      <Details/>
      <Footer/>
    </>
  )
}
