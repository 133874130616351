import React from 'react'
import Header from '../Home/Header'
import Blogs from './Blogs'
import Footer from '../Home/Footer'
import Banner from './Banner'
export default function BlogCover() {
  return (
    <>
     <Header/>
     <Banner name={'Blog'} title={'Blogs'}/>
     <Blogs/>
     <Footer/>
    </>
  )
}
