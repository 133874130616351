import React from 'react'
import { BsCardChecklist, BsFillBarChartFill } from "react-icons/bs";
import { MdMailOutline } from "react-icons/md";
import Lear from '../UI/Lear';
export default function Learn() {
  return (
    <section className='w-[100vw] h-[100%] bg-black text-white flex items-center justify-center overflow-hidden'>
      <div className='max-w-[1400px] w-[100%] px-[5%] py-[3rem] '>
        <div className='grid grid-cols-3 w-[100%] gap-10 justify-center'>
            <Lear icon={<MdMailOutline className='text-purple-600 text-[3rem]'/>} title='Lorem Ipsum' delay={0}  />
            <Lear icon={<BsCardChecklist  className='text-purple-600 text-[3rem]'/>} title=' Ipsum Lorem 'delay={0.3} />

            <Lear icon={<BsFillBarChartFill  className='text-purple-600 text-[3rem]'/>} title='Lorem Ipsum' delay={0.6}/>

        </div>
      </div>
    </section>
  )
}
